import React from 'react';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';

import { Layout } from 'containers';
import { PAGES } from 'config';

export default () => (
  <Layout page="404">
    <Helmet
      title="Page not found"
    />

    <section className="l-section">
      <div className="l-container l-container--small">
        <h1 className="c-heading c-heading--h2">Sorry This page could not be found</h1>
        <p>
          It’s all good though, just head to our home page or browse the tours. <br /> <br />
          <Link to={PAGES.TOURS.PATH} className="c-button c-button--primary">Browse Tours</Link> <br /><br />
          <Link to="/" className="c-button c-button--border">Back to home page</Link>
        </p>
      </div>
    </section>
  </Layout>
);
